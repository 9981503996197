import React from 'react';
import summer from "../assets/summer.png"



export const Summer = () => {
    return (
        <>
            <div className='about'>
                <h1>Summer Camp</h1>
                <hr />
                <img src={summer} alt="summer" />                 
            </div>
        </>
    );
}