import React from 'react';
import "./about.css";



export const About = () => {
    return (
        <>
            <div className='about'>
                <h1>About us</h1>
                <hr />
                <p>To provide aspiring Teachers and Students Value Based Quality Abacus Training and Education by adopting innovative teaching and learning practices that will create proactive leaders

                    Creating common platform to unlock his/her hidden potential to enhance Overall Competency which is a necessity in todays highly competitive world.</p>

            </div>
        </>
    );
}