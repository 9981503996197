import React from 'react';
import "./footer.css"


export const Footer = () => {
    return (
        <>
            <div className='footer-container'>
                <hr />
                <p className='usefull'><b>Usefull links:</b></p>
                <div>
                    <ul>
                        <li><a href="https://en.wikipedia.org/wiki/Abacus" target='_blank' rel="noreferrer">Why Abacus</a></li>
                        <li><a href="https://en.wikipedia.org/wiki/Abacus_school" target='_blank' rel="noreferrer">Abacus School</a></li>                                                
                    </ul>
                </div>
                <p className='version'>Contact Us: 86106-73077</p>
                <p className='version'>Version 1.0 | Publish Year 2023</p>
            </div>
        </>
    );
}